<template>
  <section>
    <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
    <div v-if="isLoading" class="text-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-card>
          <h4>Create new meta data</h4>
          <hr />
          <b-alert v-if="valiationErrorMessage" show variant="danger">{{
            valiationErrorMessage
          }}</b-alert>
          <b-container fluid>
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Meta data name</label>
                  <b-input type="text" required v-model="name"></b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Value </label>
                  <b-input type="text" required v-model="value"></b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Type</label>
                  <b-form-select v-model="metaDataType" required>
                    <option></option>
                    <option value="SpecialTxt">SpecialTxt</option>
                    <option value="PolishType">PolishType</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-button type="submit" variant="primary" class="mr-2"
                >Create</b-button
              >
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-row>
          </b-container>
        </b-card>
      </b-form>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewMetaData",
  data() {
    return {
      isLoading: true,
      error: null,
      valiationErrorMessage: null,
      name: "",
      metaDataType: "",
      value: ""
    };
  },
  created() {
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["createNewMetaData"]),
    onSubmit(e) {
      e.preventDefault();
      var self = this;
      this.isLoading = true;

      var metaData = {
        name: this.name,
        metaDataType: this.metaDataType,
        value: this.value,
        isActive: true
      };
      this.createNewMetaData(metaData)
        .then(() => {
          self.isLoading = false;
          self.name = "";
          self.metaDataType = null;
          self.value = "";
        })
        .catch(error => {
          self.error = error;
          self.isLoading = false;
        });
    },
    onReset() {
      this.name = "";
      this.metaDataType = null;
      this.value = "";
    }
  }
};
</script>

<style></style>
